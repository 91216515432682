import React from "react";
import styled from "styled-components";
import { Headline2, Paragraph } from "../../components/typography";
import HistorySectionMobile from "./history-section-mobile";
import HisotrySectionDesktop from "./history-section-desktop";
import { useMatchMedia } from "../../hooks/use-match-media";
import { PageElement } from "../../graphql/page-common";
import { getElementText } from "../../utilities/pimcore-data-transformer";
import { graphql, useStaticQuery } from "gatsby";
import { onScrollAnimationDesktop } from "../../utilities/animation-helper";

interface HistorySectionProps {
  content: Array<PageElement>;
}

const HistorySection: React.FC<HistorySectionProps> = ({ content }) => {
  const match = useMatchMedia("(min-width: 768px)");
  const [isDesktop, setDesktop] = React.useState<boolean | null>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();

  const images = useStaticQuery(graphql`
    query {
      pimcore {
        image1: getAsset(fullpath: "/pages/static/history/history1.png") {
          ...thumbnail
        }
        image2: getAsset(fullpath: "/pages/static/history/history2.png") {
          ...thumbnail
        }
        image3: getAsset(fullpath: "/pages/static/history/history3.png") {
          ...thumbnail
        }
        image4: getAsset(fullpath: "/pages/static/history/history4.png") {
          ...thumbnail
        }
        image5: getAsset(fullpath: "/pages/static/history/history5.png") {
          ...thumbnail
        }
        image6: getAsset(fullpath: "/pages/static/history/history6.png") {
          ...thumbnail
        }
        image7: getAsset(fullpath: "/pages/static/history/history7.png") {
          ...thumbnail
        }
        image8: getAsset(fullpath: "/pages/static/history/history8.png") {
          ...thumbnail
        }
        image9: getAsset(fullpath: "/pages/static/history/history9.png") {
          ...thumbnail
        }
        image10: getAsset(fullpath: "/pages/static/history/history10.png") {
          ...thumbnail
        }
        image11: getAsset(fullpath: "/pages/static/history/history11.png") {
          ...thumbnail
        }
      }
    }
  `);

  React.useEffect(() => {
    setDesktop(match);
  }, [match]);

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(
      wrapperRef.current,
      [wrapperRef?.current?.children || null],
      !match,
      ["6rem", "18rem"],
      "bottom-=25% top"
    );

    return () => {
      tl?.current?.kill();
    };
  }, [match]);

  return (
    <Wrapper>
      <Grid>
        <ContentWrapper ref={wrapperRef}>
          <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[0]) }} />
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[1]) }} />
        </ContentWrapper>
      </Grid>
      <SvgWrapper>
        {isDesktop ? (
          <HisotrySectionDesktop images={images.pimcore} />
        ) : (
          <HistorySectionMobile images={images.pimcore} />
        )}
      </SvgWrapper>
    </Wrapper>
  );
};

export default HistorySection;

const Wrapper = styled.div.attrs({
  className: "bg-gray2 relative scrollspy",
})``;
const Grid = styled.div.attrs({
  className: "grid gap-4 px-5 tablet:grid-cols-12 ",
})``;
const ContentWrapper = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 mt-8 tablet:mt-14 mb-8 tablet:mb-0",
})``;
const SvgWrapper = styled.div.attrs({
  className: "w-full relative tablet:ml-40 tablet:-mt-96",
})``;
