import React from "react";
import styled from "styled-components";

const SvgComponentMobile: React.FC = () => {
  return (
    <Wrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.361 3210">
        <rect
          id="Rectangle_4431"
          data-name="Rectangle 4431"
          width="24"
          height="3210"
          rx="12"
          transform="translate(17)"
          fill="#1f1f1f"
        />
        <path
          id="Path_2251"
          data-name="Path 2251"
          d="M0,0V3181.082"
          transform="translate(29 20)"
          fill="none"
          stroke="#2b2b2b"
          strokeWidth="2"
        />
        <path
          id="slider"
          d="M0,0V3181.082"
          transform="translate(29 75)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
        <g
          id="year_left"
          data-name="year left"
          transform="translate(-159 -6379)"
        >
          <g id="line1">
            <line
              id="Line_165"
              data-name="Line 165"
              x2="28"
              transform="translate(160 6499)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker"
              data-name="year marker"
              transform="translate(877.191 14391.191) rotate(180)"
            >
              <circle
                id="Ellipse_158"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-2"
              data-name="year marker"
              transform="translate(906 14394) rotate(180)"
            >
              <circle
                id="Ellipse_158-2"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7889)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="year_left-2"
          data-name="year left"
          transform="translate(-159 -5099)"
        >
          <g id="line9">
            <line
              id="Line_165-2"
              data-name="Line 165"
              x2="28"
              transform="translate(160 6499)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-3"
              data-name="year marker"
              transform="translate(877.191 14391.191) rotate(180)"
            >
              <circle
                id="Ellipse_158-3"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-4"
              data-name="year marker"
              transform="translate(906 14394) rotate(180)"
            >
              <circle
                id="Ellipse_158-4"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7889)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="year_left-3"
          data-name="year left"
          transform="translate(-159 -3848)"
        >
          <g id="line17">
            <line
              id="Line_172"
              data-name="Line 172"
              x2="28"
              transform="translate(188 6526.5) rotate(180)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-5"
              data-name="year marker"
              transform="translate(-553.191 -1365.191)"
            >
              <circle
                id="Ellipse_158-5"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-6"
              data-name="year marker"
              transform="translate(-530 -1316)"
            >
              <circle
                id="Ellipse_158-6"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7837)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="year_left-4"
          data-name="year left"
          transform="translate(-159 -3510)"
        >
          <g id="line19">
            <line
              id="Line_172-2"
              data-name="Line 172"
              x2="28"
              transform="translate(188 6509.5) rotate(180)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-7"
              data-name="year marker"
              transform="translate(-553.191 -1382.191)"
            >
              <circle
                id="Ellipse_158-7"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-8"
              data-name="year marker"
              transform="translate(-530 -1333)"
            >
              <circle
                id="Ellipse_158-8"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7837)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="year_left-5"
          data-name="year left"
          transform="translate(-159 -5737)"
        >
          <g id="line5">
            <line
              id="Line_165-3"
              data-name="Line 165"
              x2="28"
              transform="translate(160 6496.5)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-9"
              data-name="year marker"
              transform="translate(877.191 14389.191) rotate(180)"
            >
              <circle
                id="Ellipse_158-9"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-10"
              data-name="year marker"
              transform="translate(906 14392) rotate(180)"
            >
              <circle
                id="Ellipse_158-10"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7889)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="year_left-6"
          data-name="year left"
          transform="translate(-159 -6087)"
        >
          <g id="line3">
            <line
              id="Line_165-4"
              data-name="Line 165"
              x2="28"
              transform="translate(160 6526)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-11"
              data-name="year marker"
              transform="translate(877.191 14391.191) rotate(180)"
            >
              <circle
                id="Ellipse_158-11"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7862.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-12"
              data-name="year marker"
              transform="translate(906 14421) rotate(180)"
            >
              <circle
                id="Ellipse_158-12"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7889)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="year_left-7"
          data-name="year left"
          transform="translate(-159 -5437)"
        >
          <g id="line7">
            <line
              id="Line_165-5"
              data-name="Line 165"
              x2="28"
              transform="translate(160 6516.5)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-13"
              data-name="year marker"
              transform="translate(877.191 14382.191) rotate(180)"
            >
              <circle
                id="Ellipse_158-13"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7862.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-14"
              data-name="year marker"
              transform="translate(906 14412) rotate(180)"
            >
              <circle
                id="Ellipse_158-14"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7889)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="year_left-8"
          data-name="year left"
          transform="translate(-159 -4757)"
        >
          <g id="line11">
            <line
              id="Line_165-6"
              data-name="Line 165"
              x2="28"
              transform="translate(160 6476.5)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-15"
              data-name="year marker"
              transform="translate(877.191 14341.191) rotate(180)"
            >
              <circle
                id="Ellipse_158-15"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7862.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-16"
              data-name="year marker"
              transform="translate(906 14371) rotate(180)"
            >
              <circle
                id="Ellipse_158-16"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7889)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="year_left-9"
          data-name="year left"
          transform="translate(-159 -4384)"
        >
          <g id="line13">
            <line
              id="Line_170"
              data-name="Line 170"
              x2="28"
              transform="translate(188 6423.5) rotate(180)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-17"
              data-name="year marker"
              transform="translate(-553.191 -1468.191)"
            >
              <circle
                id="Ellipse_158-17"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-18"
              data-name="year marker"
              transform="translate(-530 -1419)"
            >
              <circle
                id="Ellipse_158-18"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7837)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="year_left-10"
          data-name="year left"
          transform="translate(-159 -4116)"
        >
          <g id="line15">
            <line
              id="Line_165-7"
              data-name="Line 165"
              x2="28"
              transform="translate(160 6475.5)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-19"
              data-name="year marker"
              transform="translate(877.191 14341.191) rotate(180)"
            >
              <circle
                id="Ellipse_158-19"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7862.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-20"
              data-name="year marker"
              transform="translate(906 14371) rotate(180)"
            >
              <circle
                id="Ellipse_158-20"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7889)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2576"
          data-name="Group 2576"
          transform="translate(-159 -6088)"
        >
          <g id="line2">
            <line
              id="Line_164"
              data-name="Line 164"
              x2="28"
              transform="translate(188 6367.5)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-21"
              data-name="year marker"
              transform="translate(929.191 14259.191) rotate(180)"
            >
              <circle
                id="Ellipse_158-21"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-22"
              data-name="year marker"
              transform="translate(906 14210) rotate(180)"
            >
              <circle
                id="Ellipse_158-22"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7837)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2577"
          data-name="Group 2577"
          transform="translate(-159 -5783)"
        >
          <g id="line4">
            <line
              id="Line_164-2"
              data-name="Line 164"
              x2="28"
              transform="translate(188 6383)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-23"
              data-name="year marker"
              transform="translate(929.191 14275.191) rotate(180)"
            >
              <circle
                id="Ellipse_158-23"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-24"
              data-name="year marker"
              transform="translate(906 14226) rotate(180)"
            >
              <circle
                id="Ellipse_158-24"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7837)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2578"
          data-name="Group 2578"
          transform="translate(-159 -5005)"
        >
          <g id="line8">
            <line
              id="Line_168"
              data-name="Line 168"
              x2="28"
              transform="translate(188 6244.5)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-25"
              data-name="year marker"
              transform="translate(929.191 14137.191) rotate(180)"
            >
              <circle
                id="Ellipse_158-25"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-26"
              data-name="year marker"
              transform="translate(906 14088) rotate(180)"
            >
              <circle
                id="Ellipse_158-26"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7837)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2579"
          data-name="Group 2579"
          transform="translate(-159 -4746)"
        >
          <g id="line10">
            <line
              id="Line_164-3"
              data-name="Line 164"
              x2="28"
              transform="translate(188 6305.5)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-27"
              data-name="year marker"
              transform="translate(929.191 14198.191) rotate(180)"
            >
              <circle
                id="Ellipse_158-27"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-28"
              data-name="year marker"
              transform="translate(906 14149) rotate(180)"
            >
              <circle
                id="Ellipse_158-28"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7837)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2580"
          data-name="Group 2580"
          transform="translate(-159 -4364)"
        >
          <g id="line12">
            <line
              id="Line_169"
              data-name="Line 169"
              x2="28"
              transform="translate(188 6243.5)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-29"
              data-name="year marker"
              transform="translate(929.191 14135.191) rotate(180)"
            >
              <circle
                id="Ellipse_158-29"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-30"
              data-name="year marker"
              transform="translate(906 14086) rotate(180)"
            >
              <circle
                id="Ellipse_158-30"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7837)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2581"
          data-name="Group 2581"
          transform="translate(-159 -4010)"
        >
          <g id="LINE14">
            <line
              id="Line_171"
              data-name="Line 171"
              x2="28"
              transform="translate(188.361 6208.5)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-31"
              data-name="year marker"
              transform="translate(929.553 14101.192) rotate(180)"
            >
              <circle
                id="Ellipse_158-31"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-32"
              data-name="year marker"
              transform="translate(906.361 14052.001) rotate(180)"
            >
              <circle
                id="Ellipse_158-32"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7837)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2582"
          data-name="Group 2582"
          transform="translate(-159 -5537)"
        >
          <g
            id="Group_2338"
            data-name="Group 2338"
            transform="translate(0 107)"
          >
            <g id="line6">
              <line
                id="Line_167"
                data-name="Line 167"
                x2="28"
                transform="translate(188 6348.5)"
                fill="none"
                stroke="#707070"
                strokeWidth="2"
                strokeDasharray="3"
              />
              <g
                id="year_marker-33"
                data-name="year marker"
                transform="translate(929.191 14240.191) rotate(180)"
              >
                <circle
                  id="Ellipse_158-33"
                  data-name="Ellipse 158"
                  cx="3"
                  cy="3"
                  r="3"
                  transform="translate(712.191 7889.191)"
                  fill="#8c0014"
                />
              </g>
              <g
                id="year_marker-34"
                data-name="year marker"
                transform="translate(906 14191) rotate(180)"
              >
                <circle
                  id="Ellipse_158-34"
                  data-name="Ellipse 158"
                  cx="6"
                  cy="6"
                  r="6"
                  transform="translate(712 7837)"
                  fill="#8c0014"
                />
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_2583"
          data-name="Group 2583"
          transform="translate(-159 -3899)"
        >
          <g id="line16">
            <line
              id="Line_164-4"
              data-name="Line 164"
              x2="28"
              transform="translate(188 6418.5)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-35"
              data-name="year marker"
              transform="translate(929.191 14311.191) rotate(180)"
            >
              <circle
                id="Ellipse_158-35"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-36"
              data-name="year marker"
              transform="translate(906 14262) rotate(180)"
            >
              <circle
                id="Ellipse_158-36"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7837)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2584"
          data-name="Group 2584"
          transform="translate(-159 -3620)"
        >
          <g id="line18">
            <line
              id="Line_173"
              data-name="Line 173"
              x2="28"
              transform="translate(188 6460.5)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="3"
            />
            <g
              id="year_marker-37"
              data-name="year marker"
              transform="translate(929.191 14353.191) rotate(180)"
            >
              <circle
                id="Ellipse_158-37"
                data-name="Ellipse 158"
                cx="3"
                cy="3"
                r="3"
                transform="translate(712.191 7889.191)"
                fill="#8c0014"
              />
            </g>
            <g
              id="year_marker-38"
              data-name="year marker"
              transform="translate(906 14304) rotate(180)"
            >
              <circle
                id="Ellipse_158-38"
                data-name="Ellipse 158"
                cx="6"
                cy="6"
                r="6"
                transform="translate(712 7837)"
                fill="#8c0014"
              />
            </g>
          </g>
        </g>
      </svg>
    </Wrapper>
  );
};

export default SvgComponentMobile;
const Wrapper = styled.div.attrs({
  className:
    "block tablet:hidden w-[3.625rem] h-[200.625rem] ml-auto mr-auto col-start-1 row-start-1 col-span-4",
})``;
