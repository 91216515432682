import React from "react";

interface HistoryTimelineData {
  year: string;
  content: string | React.ReactNode;
  image?: string;
  imageTitle?: string;
  subContent?: string;
}

export const HistoryTimelineData: Array<HistoryTimelineData> = [
  {
    year: "1899",
    content: "Miele is founded with eleven employees.",
    image: "image1",
  },
  {
    year: "1910",
    content: "Miele’s first washing machine goes on sale.",
    image: "image2",
  },
  {
    year: "1916",
    content: "Miele is so convinced of the quality of its products that a 10-year warranty is available. ",
  },
  {
    year: "1924",
    content: "Miele begins manufacture of bicycles.",
    image: "image3",
  },
  {
    year: "1931",
    content: "Miele launches its first vaccum cleaner.",
    image: "image4",
  },
  {
    year: "1932",
    content: "Miele launches motorised bicycles.",
  },
  {
    year: "1949",
    content:
      "Miele’s 50th anniversary is celebrated with all employees receiving a substantial gift of money, 20% pension increases and a donation to the benevolent fund.",
  },
  {
    year: "1958",
    content: "Miele launches the first electric tumble dryer.",
    image: "image5",
  },
  {
    year: "1967",
    content: "Creation of industrial washers for surgical instruments in hospitals and thermal disinfection.",
    image: "image6",
  },
  {
    year: "1974",
    content: "Miele launches a new range of built in kitchen appliances, to coordinate with cabinetry.",
    image: "image7",
  },
  {
    year: "1987",
    content: "Miele launches the first dishwasher with a top level cutlery tray.",
  },
  {
    year: "1993",
    content: "A fully automatic distribution centre is opened at the Gütersloh plant.",
    image: "image8",
  },
  {
    year: "2007",
    content: "Launch of ovens with ‚moisture plus’ to help achieve perfect results in the kitchen.",
  },
  {
    year: "2014",
    content:
      "Miele receives the National German Sustainability Award, one of Europe’s most coveted sustainability prizes.",
    image: "image9",
  },
  {
    year: "2016",
    content: "Miele is the first company to launch a dishwasher that can deliver an A rated wash in under 1 hour.",
  },
  {
    year: "2018",
    content: "Miele opens its nursery at the Gütersloh plant.",
  },
  {
    year: "2020",
    content: <>Miele opens its innovative digital hub for Miele worldwide, Miele&nbsp;X.</>,
    image: "image10",
  },
  {
    year: "2021",
    content: "Employs >20,000 people globally.",
    subContent: "3rd most female-friendly company (Forbes).",
  },
  {
    year: "2022",
    content: "Miele X has moved to Valley, Amsterdam.",
    image: "image11",
    imageTitle: "Valley Footage: Paul Starink",
  },
];
