import React from "react";
import styled from "styled-components";

const SvgComponentDesktop: React.FC = () => {
  return (
    <Wrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1333.397 2841.809"
        className="relative"
      >
        <g id="history_timeline" transform="translate(0 2.678)">
          <g id="line" transform="translate(-173 -7142.5)">
            <path
              id="Path_2029"
              data-name="Path 2029"
              d="M1190.326-82.732,545.543,562.051a117,117,0,0,0,0,165.463l75.141,75.139c45.69,45.691,40.373,39.172,86.064,84.863l75.139,75.145a117,117,0,0,1,0,165.463L596.743,1313.263a117,117,0,0,1-165.463,0l-45.138-45.144a117,117,0,0,0-165.463,0L55.532,1433.26a117,117,0,0,0,0,165.463l30.708,30.708,75.139,75.139c45.69,45.691,26.372,25.132,72.064,70.823l155.138,155.13c1.691,1.691,3.319,3.42,5.085,5.413a116.384,116.384,0,0,1,29.169,80.437c-.709,28.687-.3,692.637,0,724"
              transform="translate(297.393 7241.232)"
              fill="none"
              stroke="#1f1f1f"
              strokeLinejoin="round"
              strokeWidth="50"
            />
            <path
              id="Path_2004"
              data-name="Path 2004"
              d="M1191.326-83.732l-161.5,161.5-3.192,3.192L545.543,562.051a116.994,116.994,0,0,0-32.582,102.522c.357,2.132.823,4.474,1.36,6.8a116.289,116.289,0,0,0,31.221,56.137l75.141,75.139c45.69,45.691,40.373,39.172,86.064,84.863l75.139,75.145a117,117,0,0,1,0,165.463L596.743,1313.263a117,117,0,0,1-165.463,0l-45.138-45.144a117,117,0,0,0-165.463,0L55.532,1433.26a117,117,0,0,0,0,165.463l30.708,30.708,75.139,75.139c45.69,45.691,26.372,25.132,72.064,70.823l155.138,155.13c1.691,1.691,3.319,3.42,5.085,5.413a116.384,116.384,0,0,1,29.169,80.437c-.709,28.687-.3,222.637,0,254"
              transform="translate(297.393 7241.232)"
              fill="none"
              stroke="#626262"
              strokeLinejoin="round"
              strokeWidth="50"
              strokeDasharray="1 22"
              opacity="0.3"
            />
            <path
              id="Path_2127"
              data-name="Path 2127"
              d="M1206.326-98.732,545.543,562.051a117,117,0,0,0,0,165.463l75.141,75.139c45.69,45.691,40.373,39.172,86.064,84.863l75.139,75.145a117,117,0,0,1,0,165.463L596.743,1313.263a117,117,0,0,1-165.463,0l-45.138-45.144a117,117,0,0,0-165.463,0L55.532,1433.26a117,117,0,0,0,0,165.463l30.708,30.708,75.139,75.139c45.69,45.691,26.372,25.132,72.064,70.823l155.138,155.13c1.691,1.691,3.319,3.42,5.085,5.413a116.384,116.384,0,0,1,29.169,80.437c-.709,28.687-.3,220.637,0,252"
              transform="translate(297.393 7241.232)"
              fill="none"
              stroke="#8e8e8e"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="root_line"
              d="M1206.326-98.732,649.8,457.791l-104.26,104.26a117,117,0,0,0,0,165.463l75.141,75.139c45.69,45.691,40.373,39.172,86.064,84.863l75.139,75.145a117,117,0,0,1,0,165.463L596.743,1313.263a117,117,0,0,1-165.463,0l-45.138-45.144a117,117,0,0,0-165.463,0L55.532,1433.26a117,117,0,0,0,0,165.463l30.708,30.708,75.139,75.139c45.69,45.691,26.372,25.132,72.064,70.823l155.138,155.13c1.691,1.691,3.319,3.42,5.085,5.413a116.384,116.384,0,0,1,29.169,80.437c-.709,28.687-.3,220.637,0,252"
              transform="translate(297.393 7241.232)"
              fill="none"
              stroke="#fff"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
          <g
            id="dot_19"
            transform="translate(536 2355.658)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_18"
            transform="translate(536 2332.658)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_17"
            transform="translate(536 2309.658)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_16"
            transform="translate(536 2263.265)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_15"
            transform="translate(536 2219.265)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_14"
            transform="translate(558 2172.873) rotate(90)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_13"
            transform="translate(526.5 2042.873) rotate(180)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_12"
            transform="translate(299 1794.056) rotate(90)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_11"
            transform="translate(200.711 1696) rotate(90)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_10"
            transform="translate(263.711 1448.796) rotate(90)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_9"
            transform="translate(379.711 1338.224) rotate(90)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_8"
            transform="translate(535.656 1393.326)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_7"
            transform="translate(719.188 1392.326)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_6"
            transform="translate(918.711 1085.326)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_5"
            transform="translate(907.711 1065.326)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_4"
            transform="translate(795.516 949.991)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_3"
            transform="translate(665.514 821.991)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_2"
            transform="translate(630.516 694.991)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g
            id="dot_1"
            transform="translate(800.266 508.991)"
            fill="#8c0014"
            stroke="#fff"
            strokeWidth="2"
          >
            <ellipse cx="11" cy="11" rx="11" ry="11" stroke="none" />
            <ellipse cx="11" cy="11" rx="10" ry="10" fill="none" />
          </g>
          <g id="line_19" transform="translate(-173 -7142.5)">
            <path
              id="Path_2032"
              data-name="Path 2032"
              d="M0,34H146.894c11.441,0,20.716-9.85,20.716-22V0"
              transform="translate(708.609 9544) rotate(180)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_18" transform="translate(-159 -7142.5)">
            <path
              id="Path_2028"
              data-name="Path 2028"
              d="M-107,10h94"
              transform="translate(824 9478)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_17" transform="translate(-173 -7142.5)">
            <line
              id="Line_162"
              data-name="Line 162"
              y1="292"
              transform="translate(417 9463) rotate(-90)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_16" transform="translate(-173 -7142.5)">
            <path
              id="Path_2022"
              data-name="Path 2022"
              d="M0,0H147.236C158.7,0,168,9.85,168,22V34"
              transform="translate(709 9417) rotate(180)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_15" transform="translate(-161 -7142.5)">
            <path
              id="Path_2021"
              data-name="Path 2021"
              d="M0,34H405.6c11.818,0,21.4-9.85,21.4-22V0"
              transform="translate(719 9339)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_14" transform="translate(-159 -7142.5)">
            <path
              id="Path_2013"
              data-name="Path 2013"
              d="M0,0H13C25.15,0,35,9.22,35,20.594V205"
              transform="translate(922 9291) rotate(90)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_13" transform="translate(-162.5 -7142.5)">
            <path
              id="Path_2027"
              data-name="Path 2027"
              d="M0,0H61C73.15,0,83,8.573,83,19.148V70.5"
              transform="translate(759.5 9091) rotate(90)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_12" transform="translate(-160 -7142.5)">
            <path
              id="Path_2024"
              data-name="Path 2024"
              d="M50,34h60.109C120.542,34,129,24.15,129,12V-18"
              transform="translate(409 8914)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_11" transform="translate(-173 -7142.5)">
            <path
              id="Path_2025"
              data-name="Path 2025"
              d="M0,0H152.941c11.471,0,20.77,9.85,20.77,22V34"
              transform="translate(351.711 8850) rotate(180)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_10" transform="translate(-173 -7142.5)">
            <path
              id="Path_2026"
              data-name="Path 2026"
              d="M-84-24H49c12.15,0,22,8.624,22,19.263V44.3"
              transform="translate(355 8547)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_9" transform="translate(-173 -7142.5)">
            <path
              id="Path_2023"
              data-name="Path 2023"
              d="M0,43H44.535c9.493,0,17.189-9.85,17.189-22V0"
              transform="translate(499 8480.725) rotate(-90)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_8" transform="translate(-173 -7142.5)">
            <path
              id="Path_2002"
              data-name="Path 2002"
              d="M41,48V223.826"
              transform="translate(679 8312)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_7" transform="translate(-173 -7142.5)">
            <path
              id="Path_2001"
              data-name="Path 2001"
              d="M30.826,0H0"
              transform="translate(903 8504) rotate(90)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_6" transform="translate(-162.289 -7142.5)">
            <path
              id="Path_2000"
              data-name="Path 2000"
              d="M221.289,121H172"
              transform="translate(931 8118)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_5" transform="translate(-173 -7142.5)">
            <path
              id="Path_1999"
              data-name="Path 1999"
              d="M0,183.826H19c12.15,0,22-9.285,22-20.739V0"
              transform="translate(1133 8207.826) rotate(180)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_4" transform="translate(-173 -7142.5)">
            <line
              id="Line_161"
              data-name="Line 161"
              y1="56"
              transform="translate(980 8036)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_2" transform="translate(-173 -7142.5)">
            <line
              id="Line_160"
              data-name="Line 160"
              y1="147"
              transform="translate(815 7690)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_3" transform="translate(-173 -7142.5)">
            <path
              id="Path_1998"
              data-name="Path 1998"
              d="M191.514,121H20.858C9.338,121,0,111.15,0,99V81"
              transform="translate(647 7855)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <g id="line_1" transform="translate(-173 -7142.5)">
            <path
              id="line_1-2"
              data-name="line_1"
              d="M148,173.31h19c12.15,0,22-9.2,22-20.552V50"
              transform="translate(1174 7703.31) rotate(180)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
              strokeDasharray="6"
            />
          </g>
          <circle
            id="dot_line_19"
            cx="5"
            cy="5"
            r="5"
            transform="translate(373 2408.5) rotate(180)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_18"
            cx="5"
            cy="5"
            r="5"
            transform="translate(647 2340.5)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_17"
            cx="5"
            cy="5"
            r="5"
            transform="translate(239 2325.5) rotate(-90)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_16"
            cx="5"
            cy="5"
            r="5"
            transform="translate(373 2243.5) rotate(180)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_15"
            cx="5"
            cy="5"
            r="5"
            transform="translate(990 2199.5) rotate(180)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_14"
            cx="5"
            cy="5"
            r="5"
            transform="translate(768 2139.5) rotate(90)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_13"
            cx="5"
            cy="5"
            r="5"
            transform="translate(602 1943.5) rotate(90)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_12"
            cx="5"
            cy="5"
            r="5"
            transform="translate(373 1748.5)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_11"
            cx="5"
            cy="5"
            r="5"
            transform="translate(10 1675.5) rotate(180)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_10"
            cx="5"
            cy="5"
            r="5"
            transform="translate(104 1385.5) rotate(180)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_9"
            cx="5"
            cy="5"
            r="5"
            transform="translate(320 1281.5) rotate(-90)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_8"
            cx="5"
            cy="5"
            r="5"
            transform="translate(542 1213.5)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_7"
            cx="5"
            cy="5"
            r="5"
            transform="translate(735 1356.5) rotate(90)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_6"
            cx="5"
            cy="5"
            r="5"
            transform="translate(985 1091.5)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_5"
            cx="5"
            cy="5"
            r="5"
            transform="translate(955 876.5)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_4"
            cx="5"
            cy="5"
            r="5"
            transform="translate(802 892.5)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_3"
            cx="5"
            cy="5"
            r="5"
            transform="translate(479 798.5) rotate(180)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_2"
            cx="5"
            cy="5"
            r="5"
            transform="translate(637 542.5)"
            fill="#8c0014"
          />
          <circle
            id="dot_line_1"
            cx="5"
            cy="5"
            r="5"
            transform="translate(848 382.5)"
            fill="#8c0014"
          />
        </g>
      </svg>
    </Wrapper>
  );
};

export default SvgComponentDesktop;

const Wrapper = styled.div.attrs({
  className: "w-[83.34rem] h-[177.61rem] relative hidden tablet:block",
})``;
