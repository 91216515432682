import React from "react";
import styled from "styled-components";
import { Hero, HeroHeadline } from "../components/hero";
import MainSection from "../components/main-section/main-section";
import SectionGallery from "../components/section-gallery";
import BrandSection from "../sections/story/brand-section";
import CompanySection from "../sections/story/company-section";
import { JobsSearchTile, TileNavigation } from "../components/tile-navigation";
import HistorySection from "../sections/story/history-section";
import { JobOffersCountInterface } from "../graphql/jobs";
import { graphql, PageProps } from "gatsby";
import Layout from "../components/layout";
import { StoryPageContentInterface } from "../graphql/story-page";
import { transformHeroData } from "../utilities/pimcore-data-transformer";
import Scrollspy, { ScrollspyProps } from "../components/scrollspy/scrollspy";

interface StoryDataInterface {
  pimcore: {
    content: StoryPageContentInterface;
    jobOffersCount: JobOffersCountInterface;
  };
}

const labels = [
  "We are Miele X",
  "The Miele Brand",
  "The Miele X purpose",
  "Family ownership",
  "Pioneering since 1899",
];

const Story: React.FC<PageProps<StoryDataInterface>> = ({ data }) => {
  const content = data.pimcore.content;

  return (
    <Layout
      navCurrentItem="story"
      title={content.metaTitle}
      description={content.metaDescription}
      canonical={content.metaCanonical}
      openGraph={{ title: content.metaOgTitle, description: content.metaOgTitle, image: content.metaOgImage }}
      schemaOrg={content.metaSchema}
    >
      <Header>
        <Hero {...transformHeroData(content)}>
          <HeroHeadline dangerouslySetInnerHTML={{ __html: content.heroTitle || "" }} />
        </Hero>
      </Header>
      <Main>
        <MainSection
          headline={content.mainSectionHeadline}
          subHeadline={content.mainSectionSubheadline}
          content={content.mainSectionContent}
          background={content.mainSectionBackground}
          backgroundMobile={content?.mainSectionBackgroundMobile}
        >
          <SectionGallery images={content.gallery} />
        </MainSection>
        <BrandSection content={content.brand} />
        <CompanySection content={content.company} />
        <HistorySection content={content.history} />
        <TileNavigation top={"work"} bottom={"office"}>
          <JobsSearchTile jobsCount={data.pimcore.jobOffersCount.totalCount} />
        </TileNavigation>
      </Main>
      <Scrollspy labels={labels} gap={6} />
    </Layout>
  );
};

const Header = styled.header.attrs({ className: "h-screen " })``;
const Main = styled.main.attrs({
  className: "x-background overflow-x-hidden -mt-24",
})`
  background-size: 100%;
`;

export const query = graphql`
  query {
    pimcore {
      ...storyPageData
      ...jobOffersCount
    }
  }
`;

export default Story;
