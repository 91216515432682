import React from "react";
import styled from "styled-components";
import { ButtonSolid } from "../../components/button";
import { HistoryTimelineData } from "../../data/story";
import { ImageDataInterface } from "../../graphql/common";
import HistorySectionElement from "./history-section-element";
import SvgComponentMobile from "./svg-component-mobile";

interface Modifier {
  modifier?: string;
}

const modifier: Array<string> = [
  "top-0 left-0 ",
  "top-[9.5rem] right-0 ",
  "top-[22rem] left-0 ",
  "top-[30rem] right-0 ",
  "top-[41.5rem] left-0 ",
  "top-[53.5rem] right-0 ",
  "top-[59rem] left-0 ",
  "top-[70rem] right-0 ",
  "top-[77.5rem] left-0 ",
  "top-[89rem] right-0 ",
  "top-[102rem] left-0 ",
  "top-[108.5rem] right-0 ",
  "top-[122rem] left-0 ",
  "top-[127rem] right-0 ",
  "top-[141rem] left-0 ",
  "top-[152.5rem] right-0 ",
  "top-[158.5rem] left-0 ",
  "top-[170rem] right-0 ",
  "top-[179.5rem] left-0 ",
];

interface HistorySectionMobileProps {
  images: { [key: string]: ImageDataInterface };
}

interface WrapperProps {
  expanded: boolean;
}

const HistorySectionMobile: React.FC<HistorySectionMobileProps> = ({ images }) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const expandToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    const mobileSlider = document.getElementById("slider");
    const sliderWrapper = document.getElementById("Path_2251");

    const mobileHistorySlider = () => {
      const offsetTop = sliderWrapper?.getBoundingClientRect().top;
      const sliderWrapperHeight = sliderWrapper?.getBoundingClientRect().height;
      if (!offsetTop || !sliderWrapperHeight) {
        return;
      }
      const sliderScale = Math.floor(offsetTop) / Math.floor(sliderWrapperHeight);
      if (-offsetTop > -315) {
        mobileSlider?.setAttribute("transform", `translate(29 20) scale(1 ${-sliderScale + 0.1})`);
      }
    };

    if (mobileSlider) {
      mobileSlider?.setAttribute("transform", `translate(29 20) scale(1 0)`);
      document.addEventListener("scroll", mobileHistorySlider);
    }

    return () => {
      document.removeEventListener("scroll", mobileHistorySlider);
    };
  }, []);

  return (
    <>
      <GridMobile expanded={expanded}>
        <SvgComponentMobile />
        <MediaCellMobile1>
          {HistoryTimelineData.filter((el, index) => index % 2 === 0).map((el, index) => (
            <MobileWrapper modifier={modifier[2 * index]} key={`mobile-history-${index}-first`}>
              <HistorySectionElement {...el} image={images?.[el.image || ""]} />
            </MobileWrapper>
          ))}
        </MediaCellMobile1>
        <MediaCellMobile2>
          {HistoryTimelineData.filter((el, index) => index % 2 !== 0).map((el, index) => (
            <MobileWrapper modifier={modifier[2 * index + 1]} key={`mobile-history-${index}-second`}>
              <HistorySectionElement {...el} image={images?.[el.image || ""]} />
            </MobileWrapper>
          ))}
        </MediaCellMobile2>
        {!expanded && (
          <ExpandWrapper>
            <ButtonSolid href={"#"} onClick={expandToggle}>
              Show more
            </ButtonSolid>
          </ExpandWrapper>
        )}
      </GridMobile>
    </>
  );
};

export default HistorySectionMobile;
const GridMobile = styled.div.attrs<WrapperProps>(({ expanded }) => ({
  className: `tablet:hidden grid gap-4 px-8 grid-cols-4 w-full mt-12 relative -mb-10 overflow-hidden ${
    expanded ? "h-full mb-4" : "h-[64rem]"
  }`,
}))<WrapperProps>``;
const MobileWrapper = styled.div.attrs<Modifier>(({ modifier }) => ({
  className: `absolute ${modifier ? modifier : ""}`,
}))<Modifier>``;
const MediaCellMobile1 = styled.div.attrs({
  className: "col-start-1 col-span-2 row-start-1 row-span-1 relative",
})``;
const MediaCellMobile2 = styled.div.attrs({
  className: "col-start-3 col-span-2 row-start-1 row-span-1 relative",
})``;
const ExpandWrapper = styled.div.attrs({
  className:
    "absolute bottom-0 left-0 right-0 flex justify-center items-center h-72 bg-gradient-to-b from-transparent via-gray2 to-gray2",
})``;
