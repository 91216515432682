import React from "react";
import styled from "styled-components";
import { PimcoreImage } from "../../components/pimcore-image";
import { ImageDataInterface } from "../../graphql/common";

interface HistoryProps {
  year: string;
  content: string | React.ReactNode;
  image?: ImageDataInterface;
  imageTitle?: string;
  subContent?: string;
}

const HistorySectionElement: React.FC<HistoryProps> = ({ image, year, content, imageTitle, subContent }) => {
  return (
    <Wrapper>
      {image && (
        <ImgWrapper>
          <PimcoreImage
            image={image}
            modifier="max-w-full max-h-28 tablet:max-w-full tablet:max-h-36"
            withAspectRatio
          />
          {imageTitle && <ImageTitle>{imageTitle}</ImageTitle>}
        </ImgWrapper>
      )}
      <Title>{year}</Title>
      <Content>{content}</Content>
      {subContent && <SubContent>{subContent}</SubContent>}
    </Wrapper>
  );
};

export default HistorySectionElement;

const Wrapper = styled.div.attrs({
  className: `w-32 tablet:w-48 bg-gray10/30 p-2.5 tablet:p-5 rounded-lg backdrop-blur-md overflow-hidden backdrop-brightness-150 backdrop-blur-support-moz`,
})`
  @supports (-moz-appearance: none) {
    background-color: rgba(57, 57, 57, 0.95);
  }
`;
const ImgWrapper = styled.div.attrs({ className: "mb-3.5 tablet:mb-4" })``;
const Title = styled.div.attrs({
  className: "text-white text-xl tablet:text-5xl font-bold mb-1 tablet:mb-2",
})``;
const Content = styled.p.attrs({
  className: "text-gray13 text-base tablet:text-xl leading-[1.125rem] font-light",
})``;
const SubContent = styled.p.attrs({
  className: " text-gray13 text-base leading-5 tablet:text-xl font-bold mt-2",
})``;

const ImageTitle = styled.p.attrs({
  className: "hidden tablet:block text-gray5 text-xs font-light",
})`
  margin-top: 4px;
`;
