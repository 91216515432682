import React from "react";
import styled from "styled-components";
import { PimcoreImage } from "../../components/pimcore-image";
import { HeadlineProps } from "../../components/typography";
import { ImageDataInterface } from "../../graphql/common";
import { useMatchMedia } from "../../hooks/use-match-media";
import { onScrollAnimationDesktop } from "../../utilities/animation-helper";
import { ChevronIcon } from "../../images/icons/Icons";

interface BrandElemntProps {
  background?: ImageDataInterface;
  mobileBackground?: ImageDataInterface;
  headline: string;
  horizontal?: boolean;
}

interface VisibilityProps {
  horizontal?: boolean;
  $visibility?: boolean;
}

interface HeadlineText extends HeadlineProps {
  horizontal?: boolean;
}

const BrandElement: React.FC<BrandElemntProps> = ({ children, background, mobileBackground, headline, horizontal }) => {
  const [visibility, setVisibility] = React.useState(false);
  const matchMobile = useMatchMedia("(max-width: 768px)");
  const tl = React.useRef<GSAPTimeline>();
  const headlineRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(
      wrapperRef.current,
      [headlineRef.current, contentRef?.current?.children[0] || null],
      matchMobile,
      ["1rem", "6rem"],
      "center-=20% top"
    );

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <Wrapper onClick={() => setVisibility(!visibility)} ref={wrapperRef}>
      <ImgWrapper>
        {background && <PimcoreImage image={background} mobileImage={mobileBackground} withAspectRatio />}
      </ImgWrapper>
      <DesktopWrapper>
        <HeadlineWrapper horizontal={horizontal} ref={headlineRef}>
          <Headline dangerouslySetInnerHTML={{ __html: headline }} />
          <ArrowWrapper $visibility={visibility}>
            <ArrowImg src={ChevronIcon.src} alt="Chevron down icon" />
          </ArrowWrapper>
        </HeadlineWrapper>
        <ContentWrapper $visibility={visibility} horizontal={horizontal} ref={contentRef}>
          {children}
        </ContentWrapper>
      </DesktopWrapper>
    </Wrapper>
  );
};

export default BrandElement;

const Wrapper = styled.div.attrs({
  className: `rounded-lg relative overflow-hidden`,
})``;
const ImgWrapper = styled.div.attrs({
  className: "absolute inset-0 tablet:relative",
})``;
const Image = styled.img.attrs({
  className: "w-full rounded-lg absolute inset-0 tablet:relative",
})<VisibilityProps>``;
const ContentWrapper = styled.div.attrs<VisibilityProps>(({ horizontal, $visibility }) => ({
  className: `relative px-8 transition-all duration-500 ${$visibility ? "max-h-96" : "max-h-0"} ${
    horizontal ? "tablet:pl-20 tablet:mr-16 tablet:w-1/2 tablet:pr-20" : "px-9 tablet:px-16"
  } tablet:h-full `,
}))<VisibilityProps>``;
const Headline = styled.h3.attrs({
  className: "text-white text-brandMobile tablet:text-modalHeadline font-light leading-tight w-full",
})``;
const DesktopWrapper = styled.div.attrs({
  className: "tablet:absolute tablet:inset-0 relative",
})``;

const HeadlineWrapper = styled.div.attrs<HeadlineText>(({ horizontal }) => ({
  className: `tablet:mt-4 py-8 relative px-8 tablet:px-20 ${horizontal ? "tablet:text-start " : "tablet:text-center"} `,
}))<HeadlineText>``;

const ArrowWrapper = styled.div.attrs<VisibilityProps>(({ $visibility }) => ({
  className: `transition duration-300 ease-out h-5 tablet:hidden absolute flex top-1/2 -translate-y-1/2  right-8 ${
    $visibility ? "-rotate-90" : "rotate-90"
  } `,
}))<VisibilityProps>``;
const ArrowImg = styled.img.attrs({ className: "w-full h-5" })``;
