import React from "react";
import styled from "styled-components";
import QuoteElement from "../../components/quote-element/quote-element";
import { Headline2, Paragraph } from "../../components/typography";
import { theme } from "../../styles/styles";
import { PageElement } from "../../graphql/page-common";
import { getElementImage, getElementText, getElementVideo } from "../../utilities/pimcore-data-transformer";
import Video from "../../components/media/video";
import { PimcoreImage } from "../../components/pimcore-image";
import { useMatchMedia } from "../../hooks/use-match-media";
import { onScrollAnimationDesktop } from "../../utilities/animation-helper";

interface CompanySectionProps {
  content: Array<PageElement>;
}

const CompanySection: React.FC<CompanySectionProps> = ({ content }) => {
  const firstWrapper = React.useRef<HTMLDivElement>(null);
  const secondWrapper = React.useRef<HTMLDivElement>(null);
  const matchMobile = useMatchMedia("(max-width: 768px)");
  const tl = React.useRef<GSAPTimeline>();
  const tl2 = React.useRef<GSAPTimeline>();

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(firstWrapper.current, [firstWrapper?.current?.children || null], matchMobile);
    tl2.current = onScrollAnimationDesktop(
      secondWrapper.current,
      [secondWrapper?.current?.children || null],
      matchMobile
    );

    return () => {
      tl?.current?.kill();
      tl2?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <>
      <ContentWrapper>
        <Wrapper ref={firstWrapper}>
          <MediaCell1>
            <HeadlineWrapper dangerouslySetInnerHTML={{ __html: getElementText(content[0]) }} />
          </MediaCell1>
          <MediaCell3>
            <Video placeholder={getElementImage(content[1])} video={getElementVideo(content[1])} />
          </MediaCell3>
          <MediaCell2>
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[2]) }} />
          </MediaCell2>
          <MediaCell4>
            <QuoteElement
              message="Our teams are all focused on a single purpose - enabling the digital acceleration of the Miele Group."
              name="Barbara"
              position="Director Operational Excellence & Information Management"
              modifier="hidden tablet:flex tablet:right-80 tablet:bottom-64"
            />
            <PimcoreImage image={getElementImage(content[3])} modifier="w-full rounded-lg" withAspectRatio />
          </MediaCell4>
        </Wrapper>
        <Wrapper ref={secondWrapper}>
          <MediaCell5>
            <PimcoreImage image={getElementImage(content[4])} modifier="w-full rounded-lg" withAspectRatio />
          </MediaCell5>
          <MediaCell6>
            <HeadlineWrapper dangerouslySetInnerHTML={{ __html: getElementText(content[5]) }} />
          </MediaCell6>
          <MediaCell8>
            <QuoteElement
              message="We are a family business driven by business, culture and human values which give us the environment to grow sustainably and are the base for our 2030 strategy and ambitions."
              name="Simon"
              position="Miele X Managing Director"
              modifier="left-4 bottom-4 tablet:right-96 tablet:left-auto tablet:bottom-32"
              hideArrow
            />
            <PimcoreImage image={getElementImage(content[6])} modifier="w-full rounded-lg" withAspectRatio />
          </MediaCell8>
          <MediaCell7>
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[7]) }} />
          </MediaCell7>
        </Wrapper>
      </ContentWrapper>
    </>
  );
};

export default CompanySection;

const ContentWrapper = styled.div.attrs({})``;

const Wrapper = styled.div.attrs({
  className: "tablet:grid tablet:grid-cols-12 gap-4 px-5 mb-28 tablet:mb-16 last:tablet:mb-36 scrollspy",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(3, auto);
  }
`;
const MediaCell1 = styled.div.attrs({
  className: "mb-8 tablet:col-start-2 tablet:col-span-3 tablet:row-start-1 tablet:row-span-1 tablet:mb-4",
})``;
const MediaCell2 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-2 tablet:row-span-2 tablet:pr-14",
})``;
const MediaCell3 = styled.div.attrs({
  className: "mb-8 tablet:mb-0 tablet:row-start-1 tablet:row-span-2 tablet:col-start-7 tablet:col-span-5",
})``;
const MediaCell4 = styled.div.attrs({
  className: "hidden tablet:block tablet:row-start-3 tablet:row-span-1 tablet:col-start-8 tablet:col-span-4 relative",
})``;
const MediaCell5 = styled.div.attrs({
  className: "hidden tablet:block tablet:row-start-4 tablet:row-span-2 tablet:col-start-2 tablet:col-span-5",
})``;
const MediaCell6 = styled.div.attrs({
  className:
    "mt-8 pr-16 tablet:pr-0 tablet:row-start-4 tablet:row-span-1 tablet:col-start-8 tablet:col-span-4 tablet:mt-14",
})``;
const MediaCell7 = styled.div.attrs({
  className: "tablet:row-start-5 tablet:row-span-2 tablet:col-start-8 tablet:col-span-4 tablet:pr-12",
})``;
const MediaCell8 = styled.div.attrs({
  className: "mb-8 tablet:mb-0 tablet:row-start-6 tablet:row-span-1 tablet:col-start-3 tablet:col-span-4 relative",
})``;
const HeadlineWrapper = styled(Headline2).attrs({
  className: "font-bold",
})``;
