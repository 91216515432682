import React from "react";
import styled from "styled-components";
import { HistoryTimelineData } from "../../data/story";
import HistorySectionElement from "./history-section-element";
import SvgComponentDesktop from "./svg-component-desktop";
import gsap from "gsap";
import { ImageDataInterface } from "../../graphql/common";
import { createTimeline } from "../../utilities/animation-helper";

interface HisotrySectionDesktopProps {
  images: { [key: string]: ImageDataInterface };
}

interface Wrapper extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  modifier?: string;
}

const wrapperModifier: Array<string> = [
  "tablet:left-[53.4rem] tablet:bottom-[150rem]",
  "tablet:left-[35rem] tablet:bottom-[143.4rem]",
  "tablet:left-[22rem] tablet:bottom-[128rem]",
  "tablet:left-[44.5rem] tablet:bottom-[121.5rem]",
  "tablet:left-[60.1rem] tablet:bottom-[120rem]",
  "tablet:left-[62rem] tablet:bottom-[106.5rem]",
  "tablet:left-[38rem] tablet:bottom-[92.5rem]",
  "tablet:left-[24rem] tablet:bottom-[101.4rem]",
  "tablet:left-[8.2rem] tablet:bottom-[95.5rem]",
  "tablet:-left-[5.9rem] tablet:bottom-[89.5rem]",
  "tablet:-left-[5.9rem] tablet:bottom-[73.2rem]",
  "tablet:left-[18.5rem] tablet:bottom-[68rem]",
  "tablet:left-[32rem] tablet:bottom-[55.8rem]",
  "tablet:left-[45.5rem] tablet:bottom-[43.55rem]",
  "tablet:left-[59rem] tablet:bottom-[40.4rem]",
  "tablet:left-[17rem] tablet:bottom-[37.7rem]",
  "tablet:left-[3.1rem] tablet:top-[134rem]",
  "tablet:left-[40.9rem] tablet:top-[144rem]",
  "tablet:left-[17rem] tablet:top-[150.5rem]",
];

const HisotrySectionDesktop: React.FC<HisotrySectionDesktopProps> = ({ images }) => {
  const tl = React.useRef<ReturnType<typeof gsap.timeline> | null>(null);
  const historyDesktop = React.useRef<HTMLDivElement>(null);
  const contentRefs = React.useRef<Array<HTMLDivElement | null>>([]);

  React.useEffect(() => {
    contentRefs.current = contentRefs?.current?.slice(0, HistoryTimelineData.length);
  }, [HistoryTimelineData.length]);

  React.useEffect(() => {
    if (historyDesktop.current) {
      tl.current = createTimeline({
        scrollTrigger: {
          trigger: historyDesktop.current,
          start: "top center",
          end: "bottom center",
        },
      });

      const dotsElements: Array<Element | null> = [];
      const lineElements: Array<Element | null> = [];
      const dotLineElements: Array<Element | null> = [];

      for (let i = 1; i <= 19; i++) {
        dotsElements.push(historyDesktop.current.querySelector(`#dot_${i}`));
        lineElements.push(historyDesktop.current.querySelector(`#line_${i}`));
        dotLineElements.push(historyDesktop.current?.querySelector(`#dot_line_${i}`));
      }

      const contentChildrenTest: Array<any> = [];
      const contentElementsTest: Array<any> = [];
      contentRefs.current.forEach((el) => {
        contentChildrenTest.push(el?.children[0].children);
        contentElementsTest.push(el?.children[0]);
      });

      gsap.set([...dotsElements, ...lineElements, ...contentChildrenTest, ...contentElementsTest], {
        autoAlpha: 0,
        ease: "none",
      });
      gsap.set([...contentChildrenTest], { y: "25" });
      gsap.set([...dotLineElements], { autoAlpha: 0, ease: "none" });

      const dotsAndLine = { autoAlpha: 1, duration: 0.25 };
      const contentAnimation = { autoAlpha: 1, duration: 0.25 };
      const contentChildrenAnimation = {
        autoAlpha: 1,
        stagger: 0.2,
        duration: 1,
        y: 0,
      };

      for (let i = 0; i < 19; i++) {
        const tlElement = createTimeline();
        tlElement
          .to(dotsElements[i], dotsAndLine)
          .to(lineElements[i], dotsAndLine)
          .to(dotLineElements[i], dotsAndLine)
          .to(contentElementsTest[i], contentAnimation)
          .to(contentChildrenTest[i], contentChildrenAnimation);

        tl.current.add(tlElement, "-=1.75");
      }
    }

    return () => {
      if (tl.current) {
        tl.current?.kill();
      }
    };
  }, []);

  return (
    <Wrapper ref={historyDesktop}>
      <SvgComponentDesktop />
      {HistoryTimelineData.map((element, index) => (
        <HistoryWrapper
          modifier={wrapperModifier[index]}
          key={`history-desktop-${index}`}
          ref={(wrap) => (contentRefs.current[index] = wrap)}
        >
          <HistorySectionElement {...element} image={images?.[element.image || ""]} />
        </HistoryWrapper>
      ))}
    </Wrapper>
  );
};

export default HisotrySectionDesktop;

const Wrapper = styled.div.attrs({
  className: "w-full h-full relative ",
})``;

const HistoryWrapper = styled.div.attrs<Wrapper>(({ modifier }) => ({
  className: `absolute ${modifier ? modifier : ""}`,
}))<Wrapper>``;
