import React from "react";
import styled from "styled-components";
import { ButtonOutline } from "../../components/button";
import { Headline2, ParagraphProps } from "../../components/typography";
import { PageElement } from "../../graphql/page-common";
import { useMatchMedia } from "../../hooks/use-match-media";
import { onScrollAnimationDesktop, returnRefArray } from "../../utilities/animation-helper";
import { getElementImage, getElementText } from "../../utilities/pimcore-data-transformer";
import BrandElement from "./brand-element";

interface BrandSectionProps {
  content: Array<PageElement>;
}

interface ParagraphTextProps extends ParagraphProps {
  horizontal?: boolean;
}
interface PaddingWrapperProps {
  padding?: string;
}

const BrandSection: React.FC<BrandSectionProps> = ({ content }) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const matchMobile = useMatchMedia("(max-width: 768px)");
  const tl = React.useRef<GSAPTimeline>();

  React.useEffect(() => {
    const elementsIndexChildren = [0, 1, 2, 3, 4];

    const elements = returnRefArray(wrapperRef?.current?.children[1], elementsIndexChildren);

    tl.current = onScrollAnimationDesktop(
      wrapperRef.current,
      [wrapperRef?.current?.children[0] || null, ...elements],
      matchMobile,
      ["2rem", "16rem"],
      "bottom center"
    );

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <Wrapper ref={wrapperRef}>
      <Title dangerouslySetInnerHTML={{ __html: getElementText(content[0]) }} />
      <MediaWrapper>
        <MediaCell1>
          <BrandElement
            background={getElementImage(content[3])}
            mobileBackground={getElementImage(content[3], true)}
            headline={getElementText(content[1])}
          >
            <PaddingWrapper padding="pb-50 pr-4 tablet:pr-0 tablet:pb-0">
              <ParagraphText dangerouslySetInnerHTML={{ __html: getElementText(content[2]) }} />
            </PaddingWrapper>
          </BrandElement>
        </MediaCell1>
        <MediaCell2>
          <BrandElement
            background={getElementImage(content[6])}
            mobileBackground={getElementImage(content[6], true)}
            headline={getElementText(content[4])}
          >
            <PaddingWrapper padding="pb-44 pr-1 tablet:pr-0 tablet:pb-0">
              <ParagraphText dangerouslySetInnerHTML={{ __html: getElementText(content[5]) }} />
            </PaddingWrapper>
          </BrandElement>
        </MediaCell2>
        <MediaCell3>
          <BrandElement
            background={
              content[9].__typename === "Pimcore_fieldcollection_PageElementImage"
                ? content[9].fullImageDesktop
                : getElementImage(content[9])
            }
            mobileBackground={getElementImage(content[9], true)}
            headline={getElementText(content[7])}
            horizontal
          >
            <PaddingWrapper padding="pb-22 tablet:pb-0">
              <ParagraphText dangerouslySetInnerHTML={{ __html: getElementText(content[8]) }} horizontal />
              <ButtonWrapper>
                <ButtonOutline
                  href="https://www.miele.com/media/ex/com/Publikationen/miele_Sustainability_Update_2023_ENG.pdf"
                  padding="px-12 py-4"
                  target="_blank"
                >
                  Sustainability Report
                </ButtonOutline>
              </ButtonWrapper>
            </PaddingWrapper>
          </BrandElement>
        </MediaCell3>
        <MediaCell1>
          <BrandElement
            background={getElementImage(content[12])}
            mobileBackground={getElementImage(content[12], true)}
            headline={getElementText(content[10])}
          >
            <PaddingWrapper padding="pb-44 tablet:pb-0">
              <ParagraphText dangerouslySetInnerHTML={{ __html: getElementText(content[11]) }} />
            </PaddingWrapper>
          </BrandElement>
        </MediaCell1>
        <MediaCell2>
          <BrandElement
            background={getElementImage(content[15])}
            mobileBackground={getElementImage(content[15], true)}
            headline={getElementText(content[13])}
          >
            <PaddingWrapper padding="pb-40 tablet:pb-0">
              <ParagraphText dangerouslySetInnerHTML={{ __html: getElementText(content[14]) }} />
            </PaddingWrapper>
          </BrandElement>
        </MediaCell2>
      </MediaWrapper>
    </Wrapper>
  );
};

export default BrandSection;

const Wrapper = styled.div.attrs({ className: "px-5 mb-16 tablet:mb-36 scrollspy" })``;
const Title = styled(Headline2).attrs({
  className: "tablet:text-center mb-8 tablet:mb-14",
})``;
const MediaWrapper = styled.div.attrs({
  className: "grid tablet:grid-cols-12 gap-4",
})``;
const MediaCell1 = styled.div.attrs(() => ({
  className: `tablet:col-start-2 tablet:col-span-5 relative rounded-lg`,
}))``;
const MediaCell2 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-5 relative",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-10 relative",
})``;
const ButtonWrapper = styled.div.attrs({ className: "mt-8" })``;
const PaddingWrapper = styled.div.attrs<PaddingWrapperProps>(({ padding }) => ({
  className: `${padding && padding}`,
}))<PaddingWrapperProps>``;
const ParagraphText = styled.div.attrs<ParagraphTextProps>(({ horizontal }) => ({
  className: `${
    horizontal ? "tablet:text-start" : "tablet:text-center"
  } text-base leading-5	 text-gray8 tablet:text-xl font-light `,
}))<ParagraphTextProps>``;
